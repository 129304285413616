.register-con {
  padding-top: 144px;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.register-con .register_content {
  margin-bottom: 44px;
}
.register-con .register_content h6 {
  padding: 5px 25px;
  margin-bottom: 24px;
  border-radius: 15px;
  display: inline-block;
  color: var(--e-global-color-accent);
  background: rgba(40, 128, 126, 10%);
}
.register-con .register_content h2 span {
  color: var(--e-global-color-secondary);
}
.register-con .register-box {
  padding: 40px 95px 48px;
  margin-bottom: -135px;
  border-radius: 10px;
  box-shadow: 0 50px 100px 0 rgb(0 0 0 / 10%);
  background: var(--e-global-color-white);
}
.register-con .register-box label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  display: block;
  margin-bottom: 14px;
  color: var(--e-global-color-primary);
}
.register-con .register-box .form-group {
  margin-bottom: 20px;
  position: relative;
}
.register-con .register-box input,
.register-con .register-box select,
.register-con .register-box textarea {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-very-light-gray);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 18px 22px;
  width: 100%;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}
.register-con .register-box select {
  height: 55px;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat !important;
  background-position: 95.3% 22px !important;
  background-image: url(../../assets/images/dropdown-arrow.png) !important;
}
.register-con .register-box textarea {
  height: 158px;
  margin-bottom: 14px;
  resize: none;
  overflow: auto;
  transition: all 0.3s ease-in-out;
}
.register-con .register-box input:focus,
.register-con .register-box select:focus,
.register-con .register-box textarea:focus {
  color: var(--e-global-color-secondary);
  background: var(--e-global-color-white);
  border: 1px solid var(--e-global-color-secondary);
}
.register-con .register-box span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 3px;
  display: block;
  position: absolute;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-pure-red);
}
.register-con .register-box .register_button {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}
.register-con .register-box p span {
  position: relative;
  display: inline-block;
  color: var(--e-global-color-secondary);
}
.register-con .register-box .register_now {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  padding: 21px 78px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
  transition: all 0.3s ease-in-out;
  outline: none;
  border-style: none;
}
.register-con .register-box .register_now:hover {
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
}

/* Contact Page Register*/

.contactpage-register {
  padding: 140px 0;
  background: var(--e-global-color-white);
}
.contactpage-register .register-box {
  padding: 60px 95px;
  margin-bottom: 0 !important;
  box-shadow: 0 0 100px rgb(0 0 0 / 10%);
}
.contactpage-register .register-box textarea {
  padding: 22px 16px;
  height: 158px;
  margin-bottom: 41px;
}
.contactpage-register .register-box .register_now {
  padding: 19.5px;
  width: 100%;
}

.faqregister-con .register_content h2 {
  margin-bottom: 24px;
}
.faqregister-con .register-box textarea {
  padding: 22px 16px;
  height: 158px;
  margin-bottom: 42px;
}
.faqregister-con .register-box .register_now {
  padding: 19px 78px;
}
