.single_course-con {
  padding: 130px 0 100px;
}
.single_course-con .main-box {
  position: relative;
}
.single_course-con .main-box .image {
  margin-bottom: 30px;
}
.single_course-con .main-box .image img {
  border-radius: 10px;
  width: 100%;
}
.single_course-con .main-box .content1 .admin_icon {
  margin-bottom: 15px;
  display: inline-block;
}
.single_course-con .main-box .content1 i {
  font-size: 14px;
  color: var(--e-global-color-secondary);
  margin-right: 4px;
}
.single_course-con .main-box .content1 .calendar {
  font-size: 16px;
}
.single_course-con .main-box .content1 span {
  display: inline-block;
  color: var(--e-global-color-primary);
  font-family: "Inter", sans-serif;
}
.single_course-con .main-box .content1 .text-mr {
  margin-right: 10px;
}
.single_course-con .main-box .text1 {
  margin-bottom: 10px;
}
.single_course-con .main-box .text2 {
  margin-bottom: 20px;
}
.single_course-con .main-box .list {
  margin-bottom: 20px;
}
.single_course-con .main-box .list li {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin-bottom: 8px;
  padding-left: 28px;
  position: relative;
  color: var(--e-global-color-primary);
}
.single_course-con .main-box .list li i {
  height: 17px;
  width: 17px;
  line-height: 13px;
  top: 3px;
  left: 1px;
  font-size: 11px;
  border-radius: 100px;
  position: absolute;
  text-align: center;
  display: inline-block;
  color: var(--e-global-color-secondary);
  border: 2px solid var(--e-global-color-secondary);
}
.single_course-con .icon {
  position: relative;
}
.single_course-con .icon h3 {
  margin-bottom: 15px;
}
.single_course-con .icon ul li {
  padding: 0 !important;
  margin: 0 5px;
  display: inline-block;
}
.single_course-con .icon ul li:first-child {
  margin-left: 0;
}
.single_course-con .icon ul li:last-child {
  margin-right: 0;
}
.single_course-con .icon ul li i {
  display: block;
  font-size: 16px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 10px;
  left: 0 !important;
  top: 0 !important;
  text-align: center;
  color: var(--e-global-color-secondary);
  background-color: transparent;
  border: 1px solid var(--e-global-color-secondary);
  transition: all 0.3s ease-in-out;
}
.single_course-con .icon ul li i:hover {
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  border: 1px solid var(--e-global-color-secondary);
  transform: translateY(-5px);
}
.single_course-con .box {
  padding: 30px 30px 35px;
  margin-bottom: 30px;
  border-radius: 10px;
  background: var(--e-global-color-white);
  box-shadow: 0 0 50px rgb(0 0 0 / 10%);
}
.single_course-con .box h3 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--e-global-color-very-light-gray);
}
.single_course-con .box input {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-very-light-gray);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 18px 22px;
  margin-bottom: 15px;
  width: 100%;
  height: 55px;
  overflow: visible;
  outline: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}
.single_course-con .box input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--e-global-color-secondary);
  background-color: var(--e-global-color-white);
  color: var(--e-global-color-text);
}
.single_course-con .box .subscribe_now {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  border-radius: 30px;
  padding: 12px;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
  transition: all 0.3s ease-in-out;
  width: 100%;
  outline: none;
  border-style: none;
}
.single_course-con .box .subscribe_now:hover {
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
}
.single_course-con .feed {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--e-global-color-very-light-gray);
}
.single_course-con .feed:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: none !important;
}
.single_course-con .feed .feed-image {
  width: 34%;
}
.single_course-con .feed .feed-image img {
  border-radius: 4px;
}
.single_course-con .feed a {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-primary);
  text-decoration: none;
  width: 66%;
  padding-left: 16px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
.single_course-con .feed a:hover {
  color: var(--e-global-color-secondary);
}
.single_course-con ul li {
  padding-left: 10px;
}
.single_course-con ul li i {
  font-size: 6px;
  top: -3px;
  left: -8px;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: var(--e-global-color-secondary);
}
.single_course-con ul li a {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin-bottom: 16px;
  display: inline-block;
  color: var(--e-global-color-primary);
  transition: all 0.3s ease-in-out;
}
.single_course-con ul li a:hover {
  color: var(--e-global-color-secondary);
}
