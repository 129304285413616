.footer-con {
  padding-bottom: 45px;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.footer-con .middle_portion {
  padding: 95px 0 82px;
}
.footer-con .middle_portion h5 {
  margin-bottom: 16px;
  color: var(--e-global-color-secondary);
}
.footer-con .middle_portion a {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  display: inline-block;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-text);
  transition: all 0.3s ease-in-out;
}
.footer-con .middle_portion a:hover {
  color: var(--e-global-color-secondary);
}
.footer-con .middle_portion .follow .heading {
  margin-bottom: 24px;
}
.footer-con .middle_portion .social-icons li {
  display: inline-block;
  margin: 0 2px;
  transition: all 0.3s ease-in-out;
}
.footer-con .middle_portion .social-icons li:first-child {
  margin-left: 0;
}
.footer-con .middle_portion .social-icons li:last-child {
  margin-right: 0;
}
.footer-con .middle_portion .social-icons i {
  display: block;
  font-size: 14px;
  height: 45px;
  width: 45px;
  line-height: 42px;
  border-radius: 10px;
  text-align: center;
  color: var(--e-global-color-grayish-blue);
  background-color: transparent;
  border: 1px solid var(--e-global-color-grayish-blue);
  transition: all 0.3s ease-in-out;
}
.footer-con .middle_portion .social-icons li:hover i {
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-secondary);
  border: 1px solid var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
}
.footer-con .upper {
  padding: 20px 0;
  border-bottom: 1px solid rgba(40, 128, 126, 15%);
}
.footer-con .upper_portion {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-con .upper_portion ul li {
  display: inline-block;
  margin: 0 25px;
}
.footer-con .upper_portion ul li:first-child {
  margin-left: 0px;
}
.footer-con .upper_portion ul li:last-child {
  margin-right: 0px;
}
.footer-con .upper_portion a {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-primary);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.footer-con .upper_portion a:hover {
  color: var(--e-global-color-secondary);
}
.footer-con .upper_portion .active > a {
  color: var(--e-global-color-secondary);
}
.copyright {
  padding: 22px 0 24px;
  text-align: center;
  background-color: var(--e-global-color-white);
}
.copyright span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-text);
  transition: all 0.3s ease-in-out;
}
.copyright .sign {
  padding: 0 24px;
}
.copyright .pages span:hover {
  color: var(--e-global-color-secondary);
}

.footer-con .middle_portion .col-lg-3 {
  margin: 0 0px;
}
