.sponsor-con {
  padding: 138px 0 100px;
  background-color: var(--e-global-color-white);
}
.sponsor-con .sponsor_content {
  margin-bottom: 50px;
}
.sponsor-con .sponsor_content h3 {
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 6px;
}
.sponsor-con ul {
  width: 100%;
  float: left;
}
.sponsor-con ul li {
  border-right: 1px solid rgba(128, 141, 158, 15%);
  border-bottom: 1px solid rgba(128, 141, 158, 15%);
  width: 25%;
  height: 150px;
  line-height: 150px;
  transition: 0.3s all ease-in-out;
  float: left;
  text-align: center;
}
.sponsor-con ul li:last-child {
  display: inline-block;
  border-right: none;
  border-bottom: 1px solid rgba(128, 141, 158, 15%);
}
.sponsor-con .second li {
  display: inline-block;
  border-right: 1px solid rgba(128, 141, 158, 15%);
  border-bottom: none;
}
.sponsor-con .second li:last-child {
  display: inline-block;
  border-bottom: none;
}
.sponsor-con ul li:hover {
  background-color: var(--e-global-color-accent);
  box-shadow: 20px 0px 30px rgba(40, 128, 126, 20%);
}
.sponsor-con ul li:hover figure img {
  filter: brightness(0) invert(1);
}

.contactpage-sponsor {
  padding: 86px 0 100px;
}
