.article-con {
  padding: 144px 0 152px;
  background: var(--e-global-color-white);
}
.article-con .article_content {
  margin-bottom: 46px;
}
.article-con .article_content h6 {
  padding: 5px 25px;
  margin-bottom: 24px;
  border-radius: 15px;
  display: inline-block;
  color: var(--e-global-color-accent);
  background: rgba(40, 128, 126, 10%);
}
.article-con .article-box {
  border-radius: 10px;
}
.article-con .article-box .image figure {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  background-color: var(--e-global-color-black);
}
.article-con .article-box .image figure img {
  transition: all 0.3s ease-in-out;
  border-radius: 10px 10px 0 0;
}
.article-con .article-box:hover figure img {
  transform: scale(1.1);
  opacity: 0.5;
}
.article-con .article-box .image span {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  position: absolute;
  padding: 1px 10px;
  top: 14px;
  right: 10px;
  border-radius: 5px;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
}
.article-con .article-box .content {
  padding: 24px 30px 28px;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--e-global-color-very-light-gray);
  border-top: none;
  background: var(--e-global-color-white);
}
.article-con .article-box:hover .content {
  box-shadow: 0 30px 50px 0 rgb(0 0 0 / 5%);
}
.article-con .article-box .content .text-size-14 {
  display: inline-block;
  margin-bottom: 10px;
}
.article-con .article-box .content .dot {
  padding: 0 10px;
}
.article-con .article-box .content .comment {
  color: var(--e-global-color-secondary);
}
.article-con .article-box .content h4 {
  font-family: "Alegreya Sans", sans-serif;
  transition: all 0.3s ease-in-out;
}
.article-con .article-box .content h4:hover {
  color: var(--e-global-color-secondary) !important;
}
