.skill-con {
  padding: 275px 0 140px;
  background: var(--e-global-color-white);
}
.skill-con .skill_wrapper .skill-image {
  position: relative;
  left: 0;
  top: 0;
}
.skill-con .skill_wrapper .skill-image img {
  border-radius: 245px 0px 245px 245px;
}
.skill-con .skill_wrapper .icon {
  font-size: 30px;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border-radius: 3px;
  text-align: center;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  position: absolute;
}
.skill-con .skill_wrapper .cap {
  right: 34px;
  top: -10px;
  box-shadow: 5px 29px 26px rgb(40 128 126 / 10%);
}
.skill-con .skill_wrapper .arrow-up {
  left: 0;
  bottom: 62px;
  background: var(--e-global-color-black);
  box-shadow: 5px 29px 26px rgb(28 22 22 / 10%);
}
.skill-con .skill_content {
  padding-top: 55px;
}
.skill-con .skill_content h6 {
  padding: 5px 30px;
  margin-bottom: 24px;
  border-radius: 15px;
  display: inline-block;
  color: var(--e-global-color-secondary);
  background: rgba(236, 89, 81, 10%);
}
.skill-con .skill_content h2 {
  margin-bottom: 18px;
}
.skill-con .skill_content p {
  margin-bottom: 34px;
}
