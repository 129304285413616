.feature-con {
  padding: 132px 0 140px;
  background: var(--e-global-color-white);
}
.feature-con .feature_content {
  margin-bottom: 52px;
}
.feature-con .feature_content h2 {
  margin-bottom: 12px;
}
.feature-con .feature-box {
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px var(--e-global-color-very-light-gray) inset;
  transition: all 0.3s ease-in-out;
}
.feature-con .feature-box:hover {
  box-shadow: 0 0 0 1px var(--e-global-color-very-light-gray) inset,
    0 -4px 0 0 var(--e-global-color-secondary) inset;
}
.feature-con .feature-box .icon {
  margin-bottom: 34px;
  transition: all 0.3s ease-in-out;
}
.feature-con .feature-box h3 {
  margin-bottom: 15px;
}
.feature-con .feature-box:hover .icon {
  transform: translateY(-5px);
}
