.partner-con .partner_box {
  margin-top: -96px;
  padding: 42px;
  border-radius: 10px;
  background-color: var(--e-global-color-white);
  box-shadow: 0 50px 100px 0 rgb(0 0 0 / 5%);
  z-index: 1;
}
.partner-con h3 {
  margin-bottom: 38px;
}
.partner-con span {
  color: var(--e-global-color-secondary);
}
.partner-con ul {
  display: flex;
  justify-content: center;
}
.partner-con ul li {
  display: inline-block;
  margin: 0 35px;
}
.partner-con ul li:first-child {
  margin-left: 0;
}
.partner-con ul li:last-child {
  margin-right: 0;
}
.partner-con ul li figure {
  transition: all 0.3s ease-in-out;
}
.partner-con ul li figure:hover {
  filter: grayscale(100%);
}
