.error_page {
  height: 100vh;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
.error_page .error_content h1 {
  font-size: 124px;
  line-height: 130px;
  margin-bottom: 25px;
}
.error_page .error_content .text {
  font-size: 46px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--e-global-color-primary);
}
.error_page .error_content .text2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}
.error_page .error_content .back_home {
  padding: 21px 42px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
  transition: all 0.3s ease-in-out;
}
.error_page .error_content .back_home:hover {
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
}
