.chatbot_section {
    position: fixed;
    bottom: 75px;
    color: black;
    right: 25px;
    /* padding: 5px; */
    height: 500px;
    width: 350px;
    overflow-x: hidden;
    overflow-y: hidden;
    font-size: 14px;
    cursor: pointer;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.7);
    /* background-color: white; */
    box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    overflow: hidden;
    transform: translateY(100%);
    opacity: 0!important;
    transition: transform 0.4s ease, opacity 0.4s ease, bottom 0.4s ease;
}

.chatbot_section.visible {
    transform: translateY(0);
    opacity: 1 !important;
}

.chatbot_icon {
    /* position: fixed;
    bottom: 80px; */
    /* border: 2px solid red; */
    right: 5px;
    padding: 10px;
    color: white;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    margin: 20px 0px;
    /* box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2); */
}
.chatbot_icon img{
    width:65%;
}
.logo-Section{
    width: 100%;
    /* height: 30px; */
    /* border:2px solid red; */
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 10;
    overflow: hidden;
    /* box-shadow: 0 4px 2px -2px gray; */
}

.logo-chatbot{
    color: white;
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 0px;
    align-items: start;
    cursor: pointer;
    /* z-index: 10;     */
}

.chatbox-logo{
display: flex;
align-items: center;
justify-items: center;
text-align: center;
background-color: #ff822d;
font-size: 22px;
width:50px;
height: 50px;
padding: 5px 11px;
border-radius: 50%;

}
.chat-bot-content .fa-robot{
    color: white;
    background-color: #ff822d;
    margin: 2px;
    font-size: 12px;
    padding: 6px;
    border-radius: 100%;
}
.chatbot-cross .fa-xmark{
    font-size: 30px;
    margin: 10px;
}
.chatbot-online-show {
    color: green;
    border: 2px solid white;
    border-radius: 100%;
    font-size: 12px;
    margin-left: -5px;
    margin-top: 40px;
}
.chatbot-online{
    font-size: 20px;
    margin-right: 30px;
    color:rgb(102, 101, 101);
    margin-top: 20px;
    font-weight: 500;
}
.chatbot-chat-section {
    width: 100%;
    height: 89%;
    padding-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: 2px;
}
.chat-bot-content{
    /* border:2px solid red; */
    width:94%;
    margin:auto;
    margin-top: 10px;;
    
}
.chat-bot-content .fa-robot{
    color: white;
    background-color: #ff822d;
    margin: 2px;
    font-size: 12px;
    padding: 8px 6px;
    border-radius: 100%;
}
.chatbot-content-style{
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
    padding-left: 5px;
}
.bot-message{
    font-size: 1rem !important;
    width: fit-content;
    background-color: #e1dfdf;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding : 5px  ;
    margin-top: 5px;
    font-size: 16px  !important;
    margin-bottom: 5px;
    font-weight: 500;
}
.logo-chatbot-section {
    color: black;
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 0px;
    align-items: start;
    cursor: pointer;
}
.user-message{
    font-size: 1rem;
    width:fit-content;
    margin-left: auto;
    background-color: #f3f3f3 !important;
    border:1px solid rgb(187, 186, 186) !important;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding : 5px ;
    margin-top: 5px;
    font-weight: 500;
  }
  
.round-pill{
    font-size: 1rem;;
    width: fit-content;
    padding: 0px 5px;
    border-radius: 10px;
    border: 1px solid rgb(197, 195, 195);
    background-color: #e69e4282;
    font-weight: 500;
    margin: 5px;
}
.round-pill:hover{
    background-color: #ff822d;
    color:white;
    border: none;
}
.bot-image{
    z-index: 100;
}

.round-pill-fixed{
    background-color:  #ff822d;
    border: none;
    color:white;
}
.chatbot-chat-section::-webkit-scrollbar { 
    width: 6px; 
} 

.chatbot-chat-section::-webkit-scrollbar-track { 
    background:#FFFFFF;
} 

.chatbot-chat-section::-webkit-scrollbar-thumb { 
    background:  #ff822d;
} 

.chatbot-chat-section::-webkit-scrollbar-thumb:hover { 
    background:  #ff822d;
} 

.chatbot-chat-section::-webkit-scrollbar-button { 
    display: none; 
} 


@media(max-width:762px){
    .chatbot_icon {
        position: fixed;
        bottom: 80px;
        right:-23px;
        margin: 5px 0px;
    }
    .chatbot_icon img{
        width: 50%;;
    }
}
.user-input{
    margin-top: 10px;
    border : none;
    border-bottom: 2px solid #ff822d;
    outline: none;
    width:60%;
}
.chatbot_indication {
    color: rgb(0, 0, 0);
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    border-radius: 10px 0px 10px 0px;
    background-color: #e9d0b3;
    /* opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease; */
  }
  /* .chatbot_indication  .visible_indicator{
    opacity: 1;
    transform: translateY(0);
  }
  .chatbot_indication  .invisible_indicator{
    opacity: 0;
  transform: translateY(20px);
  }
   */
.shift_down{
        position: fixed;
        bottom: 20px;
        /* right: 5px;
        padding: 10px;
        color: white;
        cursor: pointer;
        text-align: center;
        z-index: 1;
        margin: 20px 0px; */
        /* box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2); */
}
.shift_up {
    position: fixed;
        bottom: 80px;
}
@media(max-width :900px){
    .chatbot_section{
        margin-right: -5px;

    }
    .chatbot_indication{
        position: fixed;
        bottom: 105px;
        right: 95px;
        color: rgb(0, 0, 0);
        font-weight: bold;
        padding: 5px;
        font-size: 12px;
        cursor: pointer;
        text-align: center;
        z-index: 1;
        border-radius: 10px 0px 10px 0px;
        background-color: #e9d0b3;

        /* box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2); */
}
      }

      @media(max-width:500px) {
        .chatbot_indication{
            bottom: 99px !important;
            position: fixed;
            right:91px !important;
        }
      }
@media(max-width :400px){
        .chatbot_section{
            margin-right: -10px;
            width: 330px;
        }
          }

@media(max-width:500px){
    .shift_up_indication{
        position: fixed;
    bottom: 120px;
    right: 127px; 
    } 
    .shift_down_indication{
        position: fixed;
    bottom: 45px !important;
    right: 127px; 
    }
}

.shift_up_indication{
    position: fixed;
    bottom: 120px;
    right: 127px;
}
.shift_down_indication{
    position: fixed;
    bottom: 65px;
    right: 127px;
}

.chatbot-minimize{
    margin-bottom: 15px;
    margin-right: 10px;;
    font-size: large;
}

.chatbot_mini{
    position: fixed;
    bottom: 0px;
    right: 0px;
    color: rgb(0, 0, 0);
    width:350px;
    height: 50px;
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    z-index: 100;
    border:1px solid rgb(186, 183, 183);
    background-color: #ffffff;
    border-radius: 5px 5px 0px 0px;
    /* border:2px solid red; */
    /* opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease; */
  }
  /* .chatbot_mini .visible{
    transform: translateY(0);/
    opacity: 1 !important;
  } */


  .chatbot_section_scale {
    position: fixed;
    bottom: -450px;
    color: black;
    right: 25px;
    /* padding: 5px; */
    height: 500px;
    width: 350px;
    overflow-x: hidden;
    overflow-y: hidden;
    font-size: 14px;
    cursor: pointer;
    z-index: 100;
    background-color: rgba(255, 255, 255);
    /* background-color: white; */
    box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    overflow: hidden;
    transform: translateY(0%);
    opacity: 1!important;
    transition: transform 0.4s ease, opacity 0.4s ease, bottom 0.4s ease;
}