#pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between the elements */
  margin-top: 20px; /* Adjust as needed */
}

#page-no-dropdown select,
#btn-container button {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

#btn-container button {
  background-color: #ff822d; /* Bootstrap primary blue */
  color: #fff;
}

#btn-container button:hover {
  background-color: #f56200;
}

#page-no-dropdown select:focus,
#btn-container button:focus {
  outline: none;
  border-color: #ff822d;
}

#page-no-dropdown select {
  min-width: 50px; /* Adjust as needed */
  text-align: center;
}

.downloadBtn:hover {
  font-style: italic;
}

.icon-hover:hover {
  color: #ff822d;
}

.Toastify__toast {
  background-color: #333; /* Set the background color */
  color: #fff; /* Set the text color */
  text-align: center;
  border-radius: 8px; /* Optional: rounded corners */
  padding: 16px; /* Optional: padding inside the toast */
}

.add_std {
  margin-top: 10%;
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 462.98px) {
  /* CSS rules for extra small devices */
  .add_std {
    margin-top: 32%;
  }
}
@media (min-width: 462px) and (max-width: 576.98px) {
  /* CSS rules for small devices */
  .add_std {
    margin-top: 28%;
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS rules for small devices */
  .add_std {
    margin-top: 19%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS rules for medium devices */
  .add_std {
    margin-top: 14%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS rules for large devices */
  .add_std {
    margin-top: 12%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* CSS rules for extra large devices */
  .add_std {
    margin-top: 12%;
  }
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* CSS rules for extra extra large devices */
  .add_std {
    margin-top: 10%;
  }
}
