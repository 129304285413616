.tm-overflow-hidden {
  overflow: hidden;
  overflow-x: hidden;
  width: 100%;
}

.tm-gray-bg {
  background-color: #f3fbfd;
}
.tm-site-footer {
  padding: 20px 0;
  background-color: #ff822d;
  color: rgba(255, 255, 255, 0.7);
  height: 200px;
}
.tm-copyright {
  margin: 0;
  margin-top: 100px;
  color: black;
  font-size: 18px !important;
  font-weight: 500;
}
.tm-footer-hotline-in h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #fff;
  margin-bottom: 3px;
}
.tm-footer-hotline {
  position: relative;
  width: 100%;
}

.tm-footer-hotline-in {
  position: absolute;
  padding-left: 55px;
  bottom: -44px;
  height: 144px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 0;
}
.tm-footer-hotline::before {
  content: "";
  width: 1500px;
  background-color: #ff822d;
  -webkit-transform: skew(20deg, 0deg);
  -ms-transform: skew(20deg, 0deg);
  transform: skew(20deg, 0deg);
  position: absolute;
  height: 151px;
  bottom: -44px;
}
.tm-section-seperator {
  height: 15px;
  width: 170px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.tm-section-seperator::before {
  content: "";
  height: 2px;
  background-color: #ff822d;
  width: 70px;
  position: absolute;
  left: 0;
  top: 6px;
}
.tm-section-seperator::after {
  content: "";
  height: 2px;
  background-color: #ff822d;
  width: 70px;
  position: absolute;
  right: 0;
  top: 6px;
}
.tm-section-seperator span {
  position: absolute;
  height: 2px;
  width: 14px;
  background-color: #ff822d;
  left: 50%;
  margin-left: -7px;
  top: 6px;
  -webkit-animation: tm-spin-animaiton 6s linear infinite;
  animation: tm-spin-animaiton 6s linear infinite;
}
.tm-section-seperator span::before {
  content: "";
  height: 7px;
  width: 2px;
  position: absolute;
  background-color: #ff822d;
  top: 2px;
  left: 6px;
}
.tm-section-seperator span::after {
  content: "";
  height: 5px;
  width: 2px;
  position: absolute;
  background-color: #b7500c;
  bottom: 3px;
  left: 6px;
}
.tm-section-heading h1 {
  /* font-size: 38px; */
  color: #ff822d;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 34px;
}
.tm-section-heading h2 {
  /* font-size: 38px; */
  color: #ff822d;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 34px;
}
.tm-section-heading p {
  line-height: 26px;
  margin-bottom: 0;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .tm-site-footer {
    padding: 40px 0;
  }

  .rtl .tm-site-footer .row.row-sm-reverce {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.tm-footer {
  background-color: #ff822d;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.para-copyright {
  color: black;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.admin-home {
  margin-top: 15%;
  margin-bottom: 2%;
}

@media (max-width: 434.98px) {
  .admin-home {
    margin-top: 35%;
  }
}

@media (min-width: 435px) and (max-width: 656.98px) {
  .admin-home {
    margin-top: 28%;
  }
}

@media (min-width: 657px) and (max-width: 985px) {
  .admin-home {
    margin-top: 22%;
  }
}
