@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Inter:wght@100..900&family=Dancing+Script:wght@400..700&display=swap");

body {
  font-family: "Alegreya Sans", sans-serif;
}

:root {
  --e-global-color-primary: #000000;
  --e-global-color-secondary: #ff822d; /* CSDC */
  --e-global-color-accent: #28807e;
  --e-global-color-text: #777777;
  --e-global-color-white: #ffffff;
  --e-global-color-black: #000000;
  /* --e-global-color-light-grayish-yellow: #fdfbf0;
  --e-global-color-light-grayish-lime-green: #ebf6ed; */
  --e-global-color-grayish-blue: #8b97ad;
  --e-global-color-dark-grayish-blue: #808d9e;
  --e-global-color-very-light-gray: #eeeeee;
  --e-global-color-vivid-orange: #f7941d;
  --e-global-color-vivid-yellow: #faa61a;
  --e-global-color-very-dark-gray: #555555;
  --e-global-color-pure-red: #ff0000;
}

h1 {
  font-size: 74px;
  line-height: 74px;
  font-weight: 700;
  color: var(--e-global-color-primary);
}
h2 {
  font-size: 50px;
  line-height: 54px;
  font-weight: 700;
  color: var(--e-global-color-primary);
}
h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: var(--e-global-color-primary);
}
h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--e-global-color-primary);
}
h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-primary);
}
h6 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-primary);
}
p {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: var(--e-global-color-text);
  font-family: "Inter", sans-serif;
}
.text-size-18 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: var(--e-global-color-text);
  font-family: "Inter", sans-serif;
}
.text-size-16 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--e-global-color-text);
  font-family: "Inter", sans-serif;
}
.text-size-14 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--e-global-color-text);
  font-family: "Inter", sans-serif;
}
a {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.default-btn {
  padding: 22px 51px;
  border-radius: 0 20px 0 20px;
  text-align: center;
  display: inline-block;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
  transition: all 0.3s ease-in-out;
}
.default-btn:hover {
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
}
.hover-effect {
  transition: all 0.3s ease-in-out;
}
.hover-effect:hover {
  transform: translateY(-5px);
}
html {
  scroll-behavior: smooth;
}
