.event-con {
  padding: 140px 0 110px;
}
.event-con .eventlogo-image {
  position: absolute;
  bottom: 0;
  left: 0;
}
.event-con .eventlogo-image img {
  opacity: 11%;
}
.event-con .event-box {
  margin-bottom: 30px;
  border-radius: 10px;
  background: var(--e-global-color-white);
  box-shadow: 0 50px 100px 0 rgb(0 0 0 / 10%);
}
.event-con .event-box .event-image {
  border-radius: 10px 10px 0 0;
  background: rgba(0, 0, 0, 90%);
}
.event-con .event-box .event-image img {
  border-radius: 10px 10px 0 0;
  opacity: 90%;
}
.event-con .event-box .content {
  padding: 26px 46px 26px 30px;
}
.event-con .event-box .content a {
  display: block;
}
.event-con .event-box h4 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 16px;
  font-family: "Alegreya Sans", sans-serif;
  transition: all 0.3s ease-in-out;
}
.event-con .event-box:hover a h4 {
  color: var(--e-global-color-secondary);
}
.event-con .event-box a:hover h4 {
  color: var(--e-global-color-accent);
}
.event-con .event-box span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-text);
  display: inline-block;
  margin-bottom: 12px;
  padding-left: 10px;
}
.event-con .event-box span i {
  font-size: 14px;
  left: -10px;
  position: relative;
  color: var(--e-global-color-secondary);
}
.event-con .event-box .dash {
  padding: 0 8px;
}
