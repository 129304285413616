.contact-con {
  background-image: url(../../assets/images/contact-background-img.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-con .contant_bg {
  padding: 130px 0 140px;
  background: rgba(0, 0, 0, 80%);
}
.contact-con .contact_content {
  margin-bottom: 52px;
}
.contact-con .contact_content h2 {
  margin-bottom: 12px;
}
.contact-con .contact-box {
  padding: 42px 54px;
  min-height: 310px;
  text-align: center;
  border-radius: 10px;
  background: var(--e-global-color-white);
}
.contact-con .contact-box .icon {
  margin-bottom: 25px;
  transition: all 0.3s ease-in-out;
}
.contact-con .contact-box:hover .icon {
  transform: translateY(-5px);
}
.contact-con .contact-box h3 {
  margin-bottom: 15px;
}
.contact-con .contact-box a {
  border-bottom: 1px solid transparent;
  color: var(--e-global-color-secondary);
}
.contact-con .contact-box a:hover {
  border-bottom: 1px solid var(--e-global-color-secondary);
}

.contactpage-contact .contant_bg {
  padding: 130px 0 242px;
}
