.instructorpage-expert {
  padding: 140px 0 86px;
  background: var(--e-global-color-white);
}
.instructorpage-expert .expert_content {
  margin-bottom: 44px;
}
.instructorpage-expert .expert-box {
  margin-bottom: 48px;
}
.instructorpage-expert .expert-box .expert-image img {
  border-radius: 0 175px 175px 175px;
}
.instructorpage-expert .expert-box .content {
  margin-top: -40px;
}

.instructorpage-sponsor {
  padding: 228px 0 100px;
}
