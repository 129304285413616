.course-con {
  padding: 130px 0 116px;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.course-con .course_content {
  margin-bottom: 62px;
}
.course-con .course_content h2 {
  margin-bottom: 12px;
}
.course-con .item {
  width: 97%;
  margin: 0 auto;
}
.course-con .course-box {
  padding: 16px 25px 36px 32px;
  border-radius: 10px;
  background: var(--e-global-color-white);
  box-shadow: 0 0 0 1px var(--e-global-color-very-light-gray) inset;
  transition: all 0.3s ease-in-out;
}
.course-con .course-box .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.course-con .course-box .price .number {
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
  color: rgb(40 128 126 / 20%);
  transition: all 0.3s ease-in-out;
}
.course-con .course-box .price .value {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  color: var(--e-global-color-primary);
  transition: all 0.3s ease-in-out;
}
.course-con .course-box .course-image {
  margin-bottom: 35px;
}
.course-con .course-box .course-image img {
  width: 290px;
  height: 200px;
  border-radius: 10px;
}
.course-con .course-box h4 {
  margin-bottom: 15px;
  font-family: "Alegreya Sans", sans-serif;
  transition: all 0.3s ease-in-out;
}
.course-con .course-box p {
  margin-bottom: 30px;
}
.course-con .course-box .read_more {
  color: var(--e-global-color-text);
  transition: all 0.3s ease-in-out;
}
.course-con .course-box .read_more i {
  font-size: 16px;
  line-height: 16px;
  margin-left: 8px;
}
.course-con .course-box:hover {
  box-shadow: 0 0 0 1px var(--e-global-color-secondary) inset;
}
.course-con .course-box:hover .number {
  color: var(--e-global-color-accent);
}
.course-con .course-box:hover .value {
  color: var(--e-global-color-secondary);
}
.course-con .course-box:hover h4 {
  color: var(--e-global-color-secondary);
}
.course-con .course-box:hover .read_more {
  color: var(--e-global-color-secondary);
}
.course-con .course-box h4:hover {
  color: var(--e-global-color-accent);
}
.course-con .course-box .read_more:hover {
  color: var(--e-global-color-accent);
}
.course-con .owl-theme .owl-nav {
  display: block !important;
  position: relative;
  margin-top: 0;
}
.course-con .owl-prev span,
.course-con .owl-next span {
  font-size: 16px;
  top: -285px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  opacity: 1;
  position: absolute;
  text-align: center;
  border-radius: 100px;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  box-shadow: 1px 0px 40px rgba(0 0 0 / 3%);
  transition: all 0.3s ease-in-out;
}
.course-con .owl-prev span {
  left: -56px;
}
.course-con .owl-next span {
  right: -56px;
}
.course-con .owl-prev span:hover,
.course-con .owl-next span:hover {
  background: var(--e-global-color-secondary);
}
