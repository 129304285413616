.sub_banner_con {
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.sub_banner_con .sub_banner_content {
  padding: 110px 0 115px;
}
.sub_banner_con .sub_banner_content h1 {
  margin-bottom: 18px;
}
.sub_banner_con .sub_banner_content .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub_banner_con .sub_banner_content p {
  font-size: 20px;
  line-height: 30px;
  color: var(--e-global-color-primary);
}
.sub_banner_con .sub_banner_content .box {
  padding: 11px 20px 10px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  background: transparent;
  border: 1px solid var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}
.sub_banner_con .sub_banner_content .box span {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  display: inline-block;
  color: var(--e-global-color-primary);
  transition: all 0.3s ease-in-out;
}
.sub_banner_con .sub_banner_content .box a:hover span {
  color: var(--e-global-color-accent);
}
.sub_banner_con .sub_banner_content .box .tilde {
  display: inline-block;
}
.sub_banner_con .sub_banner_content .box .box_span {
  color: var(--e-global-color-secondary);
}
.sub_banner_con .sub_banner_content .banner-starimage {
  position: absolute;
  left: 346px;
  top: 58px;
}
.sub_banner_con .sub_banner_content .banner-waveimage {
  position: absolute;
  left: -30px;
  bottom: 60px;
}

.about-con {
  padding: 140px 0;
  background: var(--e-global-color-white);
}
.about-con .upper_content {
  padding-bottom: 80px;
}
.about-con .about_content h6 {
  padding: 5px 25px;
  margin-bottom: 24px;
  border-radius: 15px;
  display: inline-block;
  color: var(--e-global-color-secondary);
  background: rgba(236, 89, 81, 10%);
}
.about-con .about_content h2 {
  margin-bottom: 18px;
}
.about-con .about_content p {
  margin-bottom: 28px;
  color: var(--e-global-color-very-dark-gray);
}
.about-con .about_content ul {
  margin-bottom: 35px;
}
.about-con .about_content ul li {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin-bottom: 12px;
  padding-left: 28px;
  position: relative;
  color: var(--e-global-color-primary);
}
.about-con .about_content ul li i {
  height: 17px;
  width: 17px;
  line-height: 13px;
  top: 3px;
  left: 1px;
  font-size: 11px;
  border-radius: 100px;
  position: absolute;
  text-align: center;
  display: inline-block;
  color: var(--e-global-color-secondary);
  border: 2px solid var(--e-global-color-secondary);
}
.about-con .about_content a {
  padding: 22px 45px;
}
.about-con .about_wrapper .about-image {
  position: relative;
  right: -44px;
  top: 0;
}
.about-con .about_wrapper .about-image img {
  border-radius: 0 245px 245px 245px;
}
.about-con .about_wrapper .circle {
  width: 170px;
  height: 170px;
  bottom: 0;
  left: 0;
  border-radius: 100px;
  text-align: center;
  display: table;
  background: var(--e-global-color-secondary);
  position: absolute;
}
.about-con .about_wrapper .circle .content {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px;
}
.about-con .about_wrapper .circle .number {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  display: block;
}
.about-con .about_wrapper .circle .text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.about-con .about-box {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--e-global-color-very-light-gray);
  background: var(--e-global-color-white);
  transition: all 0.3s ease-in-out;
}
.about-con .about-box:hover {
  border: 1px solid var(--e-global-color-secondary);
}
.about-con .about-box .icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  display: inline-block;
  border-radius: 100px;
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px var(--e-global-color-very-light-gray) inset;
  transition: all 0.3s ease-in-out;
}
.about-con .about-box:hover .icon {
  border: 1px solid var(--e-global-color-secondary);
  box-shadow: none;
}
.about-con .about-box .content {
  padding-left: 20px;
}

.aboutpage-article {
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.aboutpage-expert {
  background: var(--e-global-color-white);
}
.aboutpage-skill {
  padding: 140px 0;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
