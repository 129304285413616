.learning-con {
  padding: 130px 0 140px;
  background: var(--e-global-color-white);
}
.learning-con .learning_content {
  margin-bottom: 50px;
}
.learning-con .learning_content h2 {
  margin-bottom: 14px;
}
.learning-con .learning-box {
  text-align: center;
}
.learning-con .learning-box .learning-image {
  margin-bottom: 22px;
}
.learning-con .learning-box .learning-image img {
  border-radius: 0 127px 127px 127px;
}
.learning-con .learning-box h3 {
  margin-bottom: 6px;
}
.learning-con .learning-box p {
  padding: 0 10px;
  margin-bottom: 15px;
}
.learning-con .learning-box .apply_now {
  font-size: 14px;
  line-height: 14px;
  padding: 11px 26px 13px 26px;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  box-shadow: 0 10px 30px 0 rgb(40 128 126 / 20%);
  transition: all 0.3s ease-in-out;
}
.learning-con .learning-box .apply_now:hover {
  background: var(--e-global-color-secondary);
  box-shadow: 0 10px 30px 0 rgb(236 89 81 / 20%);
}

.learning-con .row:nth-child(2) {
  justify-content: center;
}

.learning-con .col-lg-3 {
  margin: 0 0px;
  padding: 0px;
}
