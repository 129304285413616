.event_detail-con {
  padding-top: 140px;
  margin-bottom: -146px;
}
.event_detail-con .event_detail_content_box {
  border-radius: 10px;
  background: var(--e-global-color-white);
  box-shadow: 0 50px 100px 0 rgb(0 0 0 / 10%);
  position: relative;
  z-index: 1;
}
.event_detail-con .event_detail_content_box .event_detail-image {
  border-radius: 10px 10px 0 0;
  background: rgba(0, 0, 0, 90%);
}
.event_detail-con .event_detail_content_box .event_detail-image img {
  border-radius: 10px 10px 0 0;
  opacity: 85%;
}
.event_detail-con .event_detail_content_box .content {
  padding: 32px 35px;
}
.event_detail-con .event_detail_content_box h4 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 22px;
  font-family: "Alegreya Sans", sans-serif;
  transition: all 0.3s ease-in-out;
}
.event_detail-con .event_detail_content_box span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-text);
  padding-left: 10px;
}
.event_detail-con .event_detail_content_box span i {
  font-size: 14px;
  left: -10px;
  position: relative;
  color: var(--e-global-color-secondary);
}
.event_detail-con .event_detail_content_box .dash {
  padding: 0 8px;
}
.event_detail-con .event_detail_content_box .date {
  margin-right: 28px;
}
