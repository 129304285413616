.header {
  padding: 20px 0px;
  position: relative;
  border-bottom: 1px solid #ececec;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
  z-index: 10;
}

.img_size {
  width: 80px !important;
  height: 55px !important;
}

.navbar-collapse ul {
  text-align: center;
  align-items: center;
  display: inherit;
}
.navbar-expand-lg {
  position: relative;
  z-index: 1;
}
.navbar-brand {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-nav li {
  margin: 0 19px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.navbar-nav li::after {
  content: "";
  height: 3px;
  width: 43px;
  background-color: var(--e-global-color-secondary);
  display: inline-block;
  position: absolute;
  top: -36px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
  transition: all 0.3s ease-in-out;
}
.navbar-nav > .active::after {
  display: block;
}
.navbar-nav li:hover::after {
  display: block;
}
.navbar-nav li:first-child {
  margin-left: 0;
}
.navbar-nav .nav-item a {
  font-size: 16px;
  line-height: 16px;
  font-weight: 450;
  color: var(--e-global-color-primary) !important;
  font-family: "Inter", sans-serif;
  transition: all 0.3s ease-in-out;
}
.navbar-nav .nav-item a:hover {
  color: var(--e-global-color-secondary) !important;
  background-color: transparent;
}
.navbar-nav .active > a {
  color: var(--e-global-color-secondary) !important;
}
.navbar-collapse .drop-down-pages {
  text-align: left;
  margin-left: 0;
}
.navbar-nav .dropdown-menu {
  position: absolute;
  left: 0;
  top: 67px;
  min-width: 210px;
  padding: 0;
  border: none;
  border-radius: 10px;
  box-shadow: 0 50px 100px 0 rgb(0 0 0 / 25%);
  background-color: var(--e-global-color-white);
}
.navbar-nav .drop-down-pages li {
  margin: 0;
  position: relative;
  border-bottom: 1px solid var(--e-global-color-white);
}
.navbar-nav .drop-down-pages .nav-item a {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  padding: 7px 20px;
  position: relative;
  color: var(--e-global-color-primary) !important;
  transition: all 0.3s ease-in-out;
}
.navbar-nav .drop-down-pages li:hover::after {
  display: none;
}
.navbar-nav .drop-down-pages li:first-child a::before {
  content: "";
  width: 24px;
  height: 14px;
  position: absolute;
  top: -12px;
  left: 8%;
  transition: all 0.3s ease-in-out;
}
.navbar-nav .drop-down-pages li:last-child {
  margin: 0;
  border: none;
}
.navbar-nav .nav-item .dropdown-item:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
}
.navbar-nav .drop-down-pages .active > a {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
}
.navbar-nav .drop-down-pages .active > a:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
}
.navbar-nav .drop-down-pages .nav-item:first-child a:hover {
  border-radius: 10px 10px 0 0;
}
.navbar-nav .drop-down-pages .active:first-child > a {
  border-radius: 10px 10px 0 0;
}
.navbar-nav .drop-down-pages .nav-item:last-child a:hover {
  border-radius: 0 0 10px 10px;
}
.navbar-nav .drop-down-pages .active:last-child > a {
  border-radius: 0 0 10px 10px;
}
.navbar-expand-lg .drop-down-pages .nav-link {
  padding-left: 0;
}
.navbar-nav li .icon i {
  font-size: 16px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  color: var(--e-global-color-secondary);
  border: 1px solid var(--e-global-color-secondary);
  background: transparent;
  transition: all 0.3s ease-in-out;
}
.navbar-nav li .icon i:hover {
  color: var(--e-global-color-white);
  border: 1px solid var(--e-global-color-accent);
  background: var(--e-global-color-accent);
}
.navbar-nav li:last-child {
  margin-right: 0;
  padding-right: 0;
  margin-left: -18px;
}
.navbar-nav .nav-item .contact_us {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 16px 25px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}
.navbar-nav .nav-item .contact_us i {
  font-size: 16px;
  margin-right: 6px;
  color: var(--e-global-color-white);
  transition: all 0.3s ease-in-out;
}
.navbar-nav .nav-item .contact_us:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-secondary);
}
.navbar-nav .active > .contact_us {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-secondary);
}
