.contact_map_con iframe {
  width: 100%;
  height: 495px;
  margin-top: -100px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  border: 20px solid var(--e-global-color-white) !important;
  box-shadow: 0 0 0 1px var(--e-global-color-very-light-gray),
    50px 0px 50px rgb(0 0 0 / 5%);
  border-radius: 10px;
}
.contact_map_con iframe:hover {
  filter: none;
}
