.event_register-con {
  padding: 98px 0 120px;
  background: var(--e-global-color-white);
}
.event_register-con .event_register_wrapper {
  margin-bottom: 44px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(128, 141, 158, 15%);
}
.event_register-con .event_register_wrapper h3 {
  margin-bottom: 15px;
  display: inline-block;
}
.event_register-con .event_register_wrapper p {
  font-size: 16px;
  line-height: 26px;
  padding-right: 46px;
}
.event_register-con .event_register_wrapper .detail {
  margin-bottom: 30px;
}
.event_register-con .event_register_wrapper h4 {
  margin-bottom: 0;
  color: var(--e-global-color-secondary);
}
.event_register-con .event_register_content h3 {
  margin-bottom: 15px;
  display: inline-block;
}
.event_register-con .event_register_content p {
  font-size: 16px;
  line-height: 26px;
  padding-right: 30px;
  margin-bottom: 36px;
}
.event_register-con .event_register_content a {
  padding: 22px 88px;
}
