.coursepage-course {
  padding: 130px 0 140px;
  background: var(--e-global-color-white);
}
.coursepage-course .course-box {
  margin-bottom: 30px;
  box-shadow: 0 0 0 1px var(--e-global-color-very-light-gray) inset,
    0 50px 50px 0 rgb(0 0 0 / 5%);
}
.coursepage-course .course_button {
  padding-top: 30px;
  text-align: center;
}
.coursepage-course .course_button .view_all {
  padding: 22px 45px;
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
}
.coursepage-course .course_button .view_all:hover {
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 126 124 / 20%);
}
