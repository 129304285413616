.tm-site-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}
.tm-header-info-wrap {
  background-color: #b85513;
  width: 100%;
}
.tm-header-info {
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm-site-header.small-height .tm-header-info {
  height: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm-header-info .a {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin-left: 40px;
}
.tm-header-info .a:first-child {
  margin-left: 0;
}
.tm-header-info .a i {
  margin-right: 5px;
}
.tm-header-info .a:hover {
  color: #ff822d;
}
.tm-header-menu {
  height: 75px;
  background-color: rgba(255, 255, 255, 0.8);
}
.tm-header-menu-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
}
.tm-site-header.small-height {
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  background-color: #fff;
  -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06);
  top: 0;
}
/*Site Branding*/
.tm-site-branding {
  width: 275px;
  position: relative;
  height: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-right: 30px;
}
.tm-site-branding::before {
  content: "";
  position: absolute;
  height: 120px;
  /* width: 500px; */
  background-color: #ffffff;
  /* -webkit-transform: skew(20deg, 0deg);
  -ms-transform: skew(20deg, 0deg);
  transform: skew(20deg, 0deg); */
  right: 22px;
  bottom: 0;
}
.tm-logo-link {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.tm-logo-link img {
  height: auto !important;
  margin-top: -45px;
  width: 90%;
}
.tm-site-title {
  line-height: 30px;
  display: inline-block;
}
.tm-site-title .a {
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 30px;
  display: inherit;
  line-height: inherit;
}
/*End Site Branding*/
/*Navigation*/
.tm-primary-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
}
.tm-primary-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.tm-primary-nav .tm-primary-nav-list {
  height: 100%;
}
.tm-primary-nav .menu-item-has-children > ul {
  width: 250px;
  padding-left: 0;
  background-color: #fff;
  position: absolute;
  top: 100%;
  display: block;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  margin-top: 25px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}
.tm-primary-nav .menu-item-has-children:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}
.tm-primary-nav .menu-item-has-children > ul > .menu-item ul {
  position: absolute;
  left: 100%;
  right: 100%;
  top: 0;
}

.tm-primary-nav .tm-primary-nav-list .menu-item {
  position: relative;
}
.tm-primary-nav .tm-primary-nav-list > .menu-item {
  margin-right: 25px;
  height: 100%;
}
.tm-primary-nav .tm-primary-nav-list > .menu-item:last-child {
  margin-right: 0px;
}
.tm-primary-nav .menu-item > .a {
  font-size: 15px;
  font-weight: bold;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #002856;
}
.tm-primary-nav .menu-item > .a {
  height: 100%;
}
.tm-primary-nav .tm-primary-nav-list li.active > .a,
.tm-primary-nav .tm-primary-nav-list .menu-item > .a:hover {
  color: #ff822d;
}
.tm-primary-nav .menu-item-has-children > ul > li .a {
  height: initial;
  display: block;
  padding: 10px 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
}
.tm-primary-nav .menu-item-has-children > ul > li .a:hover,
.tm-primary-nav .current-menu-parent .current-menu-item {
  background: rgba(0, 0, 0, 0.07);
  color: #ff822d;
}
/*Mobile Menu Button*/
.m-menu-btn {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 27px;
  cursor: pointer;
  display: none;
}
.m-menu-btn span {
  margin: 0 auto;
  position: relative;
  top: 12px;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.m-menu-btn span::before,
.m-menu-btn span::after {
  position: absolute;
  content: "";
}
.m-menu-btn span,
.m-menu-btn span::before,
.m-menu-btn span::after {
  width: 25px;
  height: 2px;
  background-color: #002856;
  display: block;
}
.m-menu-btn span::before {
  margin-top: -8px;
  transition-property: margin, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  -o-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}
.m-menu-btn span::after {
  margin-top: 8px;
  transition-property: margin, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  -o-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}
.m-menu-btn-ext span {
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.m-menu-btn-ext span::before {
  margin-top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 0s, 0.2s;
  -o-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}
.m-menu-btn-ext span::after {
  margin-top: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition-delay: 0s, 0.2s;
  -o-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}
.m-dropdown {
  display: none;
}
.m-dropdown::after {
  content: "";
  position: absolute;
  height: 30px;
  width: 36px;
  left: -9px;
  top: -6px;
}
/*End Mobile Menu Button*/
.tm-primary-nav .tm-primary-nav-list {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.nav-link {
  position: relative;
}
.tm-primary-nav .menu-item > .nav-link.active {
  color: #ff822d;
}
.tm-header3 .tm-header-info-wrap {
  background-color: rgba(255, 255, 255, 0.8);
  /* border-bottom: 1px solid #e9e9e9; */
}
.tm-header3 .tm-header-info .a {
  color: #002856;
  font-weight: 600;
}
.tm-header3 .tm-header-info .a:hover {
  color: #ff822d;
}
.tm-header3.cs-type1 .tm-site-header {
  background-color: #fff;
}
.tm-headline {
  color: #002856;
  font-weight: 400 !important;
}

/* Media Query */

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Raleway:400,500,600,700");

.tm-site-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}
.tm-header-info-wrap {
  background-color: #002856;
  width: 100%;
}
.tm-header-info {
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm-site-header.small-height .tm-header-info {
  height: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm-header-info .a {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin-left: 40px;
}
.tm-header-info .a:first-child {
  margin-left: 0;
}
.tm-header-info .a i {
  margin-right: 5px;
}
.tm-header-info .a:hover {
  color: #ff822d;
}
.tm-header-menu {
  height: 75px;
  background-color: rgba(255, 255, 255, 0.8);
}
.tm-header-menu-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
}
.tm-site-header.small-height {
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  background-color: #fff;
  -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.06);
  top: 0;
}
/*Site Branding*/
.tm-site-branding {
  width: 275px;
  position: relative;
  height: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-right: 30px;
}
.tm-site-branding::before {
  content: "";
  position: absolute;
  height: 120px;
  /* width: 1500px; */
  /* background-color: #002856; */
  -webkit-transform: skew(20deg, 0deg);
  -ms-transform: skew(20deg, 0deg);
  transform: skew(20deg, 0deg);
  right: 22px;
  bottom: 0;
}
.tm-logo-link {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.tm-logo-link img {
  height: auto !important;
  margin-top: -45px;
  width: 90%;
}
.tm-site-title {
  line-height: 30px;
  display: inline-block;
}
.tm-site-title .a {
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 30px;
  display: inherit;
  line-height: inherit;
}
/*End Site Branding*/
/*Navigation*/
.tm-primary-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
}
.tm-primary-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.tm-primary-nav .tm-primary-nav-list {
  height: 100%;
}
.tm-primary-nav .menu-item-has-children > ul {
  width: 250px;
  padding-left: 0;
  background-color: #fff;
  position: absolute;
  top: 100%;
  display: block;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  margin-top: 25px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
}
.tm-primary-nav .menu-item-has-children:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}
.tm-primary-nav .menu-item-has-children > ul > .menu-item ul {
  position: absolute;
  left: 100%;
  right: 100%;
  top: 0;
}

.tm-primary-nav .tm-primary-nav-list .menu-item {
  position: relative;
}
.tm-primary-nav .tm-primary-nav-list > .menu-item {
  margin-right: 25px;
  height: 100%;
}
.tm-primary-nav .tm-primary-nav-list > .menu-item:last-child {
  margin-right: 0px;
}
.tm-primary-nav .menu-item > .a {
  font-size: 15px;
  font-weight: bold;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #002856;
}
.tm-primary-nav .menu-item > .a {
  height: 100%;
}
.tm-primary-nav .tm-primary-nav-list li.active > .a,
.tm-primary-nav .tm-primary-nav-list .menu-item > .a:hover {
  color: #ff822d;
}
.tm-primary-nav .menu-item-has-children > ul > li .a {
  height: initial;
  display: block;
  padding: 10px 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
}
.tm-primary-nav .menu-item-has-children > ul > li .a:hover,
.tm-primary-nav .current-menu-parent .current-menu-item {
  background: rgba(0, 0, 0, 0.07);
  color: #ff822d;
}
/*Mobile Menu Button*/
.m-menu-btn {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 27px;
  cursor: pointer;
  display: none;
}
.m-menu-btn span {
  margin: 0 auto;
  position: relative;
  top: 12px;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.m-menu-btn span::before,
.m-menu-btn span::after {
  position: absolute;
  content: "";
}
.m-menu-btn span,
.m-menu-btn span::before,
.m-menu-btn span::after {
  width: 25px;
  height: 2px;
  /* background-color: #002856; */
  display: block;
}
.m-menu-btn span::before {
  margin-top: -8px;
  transition-property: margin, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  -o-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}
.m-menu-btn span::after {
  margin-top: 8px;
  transition-property: margin, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  -o-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}
.m-menu-btn-ext span {
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.m-menu-btn-ext span::before {
  margin-top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 0s, 0.2s;
  -o-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}
.m-menu-btn-ext span::after {
  margin-top: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition-delay: 0s, 0.2s;
  -o-transition-delay: 0s, 0.2s;
  transition-delay: 0s, 0.2s;
}
.m-dropdown {
  display: none;
}
.m-dropdown::after {
  content: "";
  position: absolute;
  height: 30px;
  width: 36px;
  left: -9px;
  top: -6px;
}
/*End Mobile Menu Button*/
.tm-primary-nav .tm-primary-nav-list {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.nav-link {
  position: relative;
}
.tm-primary-nav .menu-item > .nav-link.active {
  color: #ff822d;
}
.tm-header3 .tm-header-info-wrap {
  background-color: rgba(255, 255, 255, 0.8);
  /* border-bottom: 1px solid #e9e9e9; */
}
.tm-header3 .tm-header-info .a {
  color: #002856;
  font-weight: 600;
}
.tm-header3 .tm-header-info .a:hover {
  color: #229cd6;
}
.tm-header3.cs-type1 .tm-site-header {
  background-color: #fff;
}
.tm-headline {
  color: #002856;
  font-weight: 400 !important;
}

@media screen and (max-width: 991px) {
  /*== Home page Header ==*/
  .m-menu {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .tm-site-header > div {
    width: 100%;
    padding: 0;
  }
  .m-menu-btn {
    display: block;
  }

  .tm-primary-nav-list {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    display: block;
    max-height: 315px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0;
    margin: 0;
    background: #fff;
    -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 0;
  }

  .small-height .tm-primary-nav-list {
    margin: 0;
  }
  .m-menu ul {
    list-style: none;
  }
  .m-menu ul .menu-item .a {
    color: #000000;
    display: block;
    padding: 6px 20px;
    font-size: 15px;
    font-weight: 600;
  }
  .m-menu ul .menu-item .a:hover {
    background-color: rgba(43, 43, 43, 0.23);
    -webkit-box-shadow: 0 0 1px 0px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 1px 0px rgba(255, 255, 255, 0.1);
  }
  .m-dropdown {
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 16px;
    color: #002856;
    font-size: 10px;
    line-height: 18px;
    height: 20px;
    width: 20px;
    text-align: center;
    z-index: 11;
    cursor: pointer;
    border: 1px solid #002856;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-left: 1px;
  }
  .m-menu ul .menu-item ul {
    display: none;
    padding-left: 10px;
  }
  .m-menu ul > .menu-item {
    position: relative;
  }
  .menu-item.menu-item-has-children > .a::after {
    display: none;
  }
  .nav-link::before {
    left: 10px;
    bottom: initial;
    top: 13px;
  }
  .nav-link.active::before {
    display: none;
  }
  .tm-primary-nav-list .menu-item > .nav-link.active {
    color: #ff822d;
  }
}
