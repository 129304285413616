/* Default styles */
.dropdown-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Proper gap between dropdowns */
  justify-content: space-between;
  align-items: center; /* Align items to the top */
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px; /* Padding around the container */
}

.filter_dropdown {
  flex: 1 1 calc(25% - 10px); /* Flex-grow, flex-shrink, flex-basis */
  margin-bottom: 15px;
}

.apply-button {
  flex: 0 0 auto; /* Prevent button from growing */
  padding: 8px 15px; /* Adjust padding for button */
  margin-bottom: -12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #fd7e14;
  color: white;
  cursor: pointer;
}

.apply-button:focus {
  outline: none;
}

.apply-button:hover {
  background-color: #f56e00;
}

/* Style for labels */
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Style for select elements */
select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .filter_dropdown {
    flex: 1 1 calc(33.33% - 10px); /* Three columns on small laptops */
  }
}

@media (max-width: 992px) {
  .filter_dropdown {
    flex: 1 1 calc(50% - 10px); /* Two columns on tablets */
  }
}

@media (max-width: 768px) {
  .filter_dropdown {
    flex: 1 1 100%; /* One column on mobile */
  }

  .apply-button {
    flex: 1 0 100%; /* Full width on mobile */
    margin-bottom: 12px;
  }
}
