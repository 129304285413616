.choose-con {
  padding: 92px 0 140px;
}
.choose-con .choose_content {
  margin-bottom: 54px;
}
.choose-con .choose_content h2 {
  margin-bottom: 24px;
}
.choose-con .choose_content span {
  color: var(--e-global-color-secondary);
}
.choose-con .choose-image img {
  border-radius: 10px;
}
.choose-con .choose_content_wrapper {
  padding-top: 22px;
}
.choose-con .choose_content_wrapper .detail {
  margin-bottom: 14px;
}
.choose-con .choose_content_wrapper h4 {
  font-size: 19px;
  margin-bottom: 0;
  color: var(--e-global-color-secondary);
}
.choose-con .choose_content_wrapper p {
  font-size: 16px;
  line-height: 26px;
}
