.container1 {
  margin: 5% auto !important;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.detail-item {
  margin-bottom: 10px;
}

.detail-label {
  font-weight: bold;
}

h2 {
  margin-top: 0;
  color: #333;
}
