.notification-toast-container {
  position: relative;
  min-height: 100px;
}

.my-toast {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: white;
  font-size: 20px;
  border-radius: 10px;
}

.circle-check {
  margin-left: 8px;
}
