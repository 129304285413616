.video-con {
  background-image: url(../../assets/images/video-background-img.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.video-con .video_bg {
  padding: 140px 0;
  background: rgba(0, 0, 0, 80%);
}
.video-con .video_content {
  text-align: center;
}
.video-con .video_content .play-icon {
  margin-bottom: 30px;
  display: inline-block;
}
.video-con .video_content h2 {
  margin-bottom: 22px;
}
.video-con .video_content p {
  margin-bottom: 34px;
}
.video-con .video_content .play-button {
  padding: 22px 35px;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  transition: all 0.3s ease-in-out;
}
.video-con .video_content .play-button i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 8px;
  color: var(--e-global-color-white);
}
.video-con .video_content .play-button:hover {
  background: var(--e-global-color-accent);
}

/* video pop up */

.video-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.video-popup {
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px;
  margin: auto;
}

.video-popup-content {
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}

.close-btn:hover {
  background-color: rgb(211, 34, 34);
}
