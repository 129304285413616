.card {
  width: 500px;
  max-width: 100%;
}

.loginButton {
  box-shadow: none;
  background-color: #ff822d;
  border: none;
  color: white;
  padding: 5px 0;
}

.loginButton:hover {
  color: white;
  background-color: #f56506;
}
