.potential-con .potential_all_content {
  padding: 132px 0 92px;
  border-bottom: 1px solid rgba(128, 141, 158, 15%);
}
.potential-con .potential_content {
  margin-bottom: 54px;
}
.potential-con .potential_content h2 {
  margin-bottom: 12px;
}
.potential-con .potential_content span {
  color: var(--e-global-color-secondary);
}
.potential-con .potential_content_box {
  padding: 70px 62px 40px 98px;
  margin-bottom: 30px;
  position: relative;
  border-radius: 10px;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.potential-con .box_content {
  position: relative;
  display: flex;
}
.potential-con .content {
  width: 46%;
}
.potential-con .content h6 {
  padding: 6px 24px;
  margin-bottom: 24px;
  border-radius: 15px;
  display: inline-block;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
}
.potential-con .content a {
  padding: 22px 60px;
}
.potential-con .image {
  width: 54%;
  position: relative;
}
.potential-con .image .potential-image {
  position: absolute;
  top: 0;
  right: 0;
}
.potential-con .image .potential-image img {
  border-radius: 10px;
  border: 5px solid var(--e-global-color-white);
}
.potential-con .image .potential-icon {
  position: absolute;
  right: 317px;
  top: 63px;
  text-align: center;
  display: inline-block;
  border-radius: 50px 50px 0 50px;
  background: var(--e-global-color-secondary);
}
.potential-con .image .box {
  position: absolute;
  top: 185px;
  right: 205px;
  padding: 28px 45px 35px 30px;
  border-radius: 10px;
  background: var(--e-global-color-white);
}
.potential-con .image .box sup {
  font-size: 28px;
  line-height: 28px;
}
.potential-con .image .box span {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 18px;
  display: inline-block;
  color: var(--e-global-color-primary);
}
.potential-con .image .box .month {
  font-size: 20px;
  line-height: 20px;
}
.potential-con .image .box li {
  padding-left: 8px;
  margin-bottom: 6px;
}
.potential-con .image .box li:last-child {
  margin-bottom: 0;
}
.potential-con .image .box li i {
  font-size: 16px;
  left: -8px;
  position: relative;
  color: var(--e-global-color-secondary);
}
.potential-con .image .box li .cross {
  color: var(--e-global-color-very-dark-gray);
}
.potential-con .carousel-inner {
  height: 464px;
}
.potential-con .pagination-outer {
  position: absolute;
  bottom: 10px;
  left: 100px;
  display: inline-block;
}
#potentialcarouselExample .carousel-control-prev {
  position: relative;
  left: 0;
  top: -50px;
  opacity: 1;
  width: auto;
}
#potentialcarouselExample .carousel-control-next {
  position: relative;
  right: -66px;
  opacity: 1;
  width: auto;
}
#potentialcarouselExample .carousel-control-prev i,
#potentialcarouselExample .carousel-control-next i {
  font-size: 18px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100px;
  color: var(--e-global-color-black);
  background: var(--e-global-color-white);
  box-shadow: 0 0 100px rgb(0 0 0 / 10%);
  transition: all 0.3s ease-in-out;
}
#potentialcarouselExample .carousel-control-prev i:hover,
#potentialcarouselExample .carousel-control-next i:hover {
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 80px 0 rgb(236 89 81 / 50%);
}
.potential-con .text {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  font-family: "Alegreya Sans", sans-serif;
  color: var(--e-global-color-primary);
}
.potential-con .text a {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--e-global-color-secondary);
  color: var(--e-global-color-secondary);
}
.potential-con .text a:hover {
  border-bottom: 1px solid var(--e-global-color-accent);
  color: var(--e-global-color-accent);
}
