.faqassistance-con {
  padding: 100px 0;
}
.faqassistance-con .faq-image {
  border-radius: 10px;
  background: rgba(0, 0, 0, 90%);
}
.faqassistance-con .faq-image img {
  border-radius: 10px;
  opacity: 90%;
}
.faqassistance-con .faqassistance_content h3 {
  margin-bottom: 18px;
}
.faqassistance-con .faqassistance_content p {
  padding-right: 55px;
  margin-bottom: 32px;
}
.faqassistance-con .faqassistance_content a {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  padding: 21px 53px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
  transition: all 0.3s ease-in-out;
}
.faqassistance-con .faqassistance_content a:hover {
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
}
