.banner_outer .banner-topdotedimage {
  position: absolute;
  top: 0;
  right: 145px;
  z-index: 12;
}

#button {
  border: none;
  outline: none;
  display: inline-block;
  background-color: var(--e-global-color-secondary);
  width: 54px;
  height: 54px;
  text-align: center;
  border-radius: 10px;
  position: fixed;
  bottom: 50px;
  right: 80px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: spineer 2s infinite;
  animation: spineer 2s infinite;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  /* icon */
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  font-weight: 600;
}
#whatsapp {
  border: none;
  outline: none;
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  position: fixed;
  bottom: 60px;
  left: 80px;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}
/* #button::after {
  content: "\f062";
  font-family: "Font Awesome 5 free";
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  font-weight: 600;
} */

#whatsapp::after {
  content: url("../images/whatsapp.png");
  line-height: 20px;
}

#button.show,
#whatsapp.show {
  opacity: 1;
  visibility: visible;
  border: none;
  z-index: 200;
}

#button:hover {
  transform: translateY(-5px);
}

@-webkit-keyframes spineer {
  from {
    box-shadow: 0 0 0 0 rgb(255, 154, 82);
  }
  to {
    box-shadow: 0 0 0 35px rgba(250, 87, 51, 0.01);
  }
}

@keyframes spineer {
  from {
    box-shadow: 0 0 0 0 rgb(255, 154, 82);
  }
  to {
    box-shadow: 0 0 0 35px rgba(250, 87, 51, 0.01);
  }
}

@media (max-width: 1200px) {
  #button {
    position: fixed;
    bottom: 10px;
  }
}

/* Job page */

.job-card {
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.job-title {
  color: #ff822d;
  font-weight: bold;
  font-size: 1.25rem;
}

.apply-btn {
  background-color: #ff822d;
  border: none;
  color: white;
}

.apply-btn:hover {
  background-color: #ff6d00;
  color: white;
}

.apply-btn .btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 166, 0, 0.774);
}

.apply-btn .btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff822d;
  border-color: #ff822d;
}

.apply-btn .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 166, 0, 0.774);
}

.submit-btn .btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 166, 0, 0.774);
}

.apply-btn .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff822d;
  border-color: #ff822d;
}

.apply-btn .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 166, 0, 0.774);
}

.apply-btn .btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #ff822d;
  border-color: #ff822d;
}
/* .modal-title {
  color: #ff822d;
  font-weight: bold;
}

button {
  border-radius: 6px;
}

button:focus {
  box-shadow: none;
} */

@media (max-width: 768px) {
  .job-title {
    font-size: 1.15rem;
  }
}
