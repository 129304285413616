.banner-con {
  padding: 146px 0 230px;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.banner-con .banner-starimage {
  position: absolute;
  left: 360px;
  top: -90px;
}
.banner-con .banner_wrapper .banner-image {
  position: relative;
  left: 0;
  top: 0;
}
.banner-con .banner_wrapper .banner-image img {
  border-radius: 0 270px 270px 270px;
}
.banner-con .banner_wrapper .icon {
  font-size: 30px;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border-radius: 3px;
  text-align: center;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  position: absolute;
}
.banner-con .banner_wrapper .graduate {
  left: -30px;
  top: -14px;
  box-shadow: 5px 29px 26px rgb(40 128 126 / 10%);
}
.banner-con .banner_wrapper .medal {
  right: -14px;
  bottom: 112px;
  background: var(--e-global-color-primary);
  box-shadow: 5px 29px 26px rgb(28 22 22 / 10%);
}
.banner-con .banner_wrapper2 .medal {
  right: 0;
}
.banner-con .banner_wrapper .banner-waveimage {
  position: absolute;
  left: -28px;
  bottom: -28px;
}
.banner-con .banner_wrapper .content {
  position: absolute;
  right: 0;
  top: 30px;
  padding: 15px 70px 15px 30px;
  border-radius: 0 40px 0 40px;
  background: rgb(255 255 255 / 80%);
}
.banner-con .banner_wrapper .content h3 {
  margin-bottom: -4px;
  color: var(--e-global-color-secondary);
}
.banner-con .banner_wrapper .content span {
  color: var(--e-global-color-primary);
}
.banner-con .banner_content {
  padding: 60px 0 0 40px;
}
.banner-con .banner_content h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 17px;
  font-family: "Dancing Script", cursive;
  color: var(--e-global-color-primary);
}
.banner-con .banner_content h1 {
  padding-left: 20px;
  margin-bottom: 4px;
  border-left: 5px solid var(--e-global-color-secondary);
}
.banner-con .banner_content .content {
  padding-left: 30px;
}
.banner-con .banner_content p {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 36px;
  color: var(--e-global-color-primary);
}
.banner-con .banner_content .banner_button {
  padding: 21px 42px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
  transition: all 0.3s ease-in-out;
}
.banner-con .banner_content .banner_button:hover {
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
}
.banner-con .carousel-inner {
  padding: 16px 0px 28px 31px;
  margin: 0 0 0 -31px;
  width: 103%;
}
.banner-con .carousel-indicators {
  align-items: center;
  bottom: -110px;
}
.banner-con .carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  opacity: 1;
  text-indent: 1px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  margin: 0 3px;
  border-radius: 100px;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px var(--e-global-color-accent) inset;
}
.banner-con .carousel-indicators li:first-child {
  margin-left: 0;
}
.banner-con .carousel-indicators li:last-child {
  margin-right: 0;
}
.banner-con .carousel-indicators .active {
  width: 15px;
  height: 15px;
  line-height: 15px;
  background-color: var(--e-global-color-accent);
}
