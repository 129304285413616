.faq-con {
  padding-top: 132px;
  background: var(--e-global-color-white);
}
.faq-con .faq_content {
  margin-bottom: 54px;
}
.faq-con .faq_content h2 {
  margin-bottom: 12px;
}
.faq-con .faq_content span {
  color: var(--e-global-color-secondary);
}
.faq-con .faq-box {
  padding: 40px;
  border-radius: 10px;
  border: 1px solid var(--e-global-color-accent);
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.faq-con .accordion-card {
  margin-bottom: 34px;
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.faq-con .accordion-card:last-child {
  margin-bottom: 0;
}
.faq-con .accordion-card:has(.btn-link) {
  background: var(--e-global-color-accent);
  box-shadow: 10px 0 68px rgba(237, 237, 237, 60%);
}
.faq-con .accordion-card:has(.collapsed) {
  background: var(--e-global-color-white);
  box-shadow: 50px 0 100px rgb(0 0 0 / 10%);
}
.faq-con .accordion-card h3 {
  margin-bottom: 0;
  outline: none;
  border: none;
  box-shadow: none;
  display: inline-block;
  color: var(--e-global-color-primary);
  font-family: "Alegreya Sans", sans-serif;
  transition: all 0.3s ease-in-out;
}
.faq-con .accordian-inner .accordion-card .btn-link h3 {
  color: var(--e-global-color-white);
}
.faq-con .accordian-inner .accordion-card .collapsed h3 {
  color: var(--e-global-color-primary);
}
.faq-con .accordian-inner p {
  font-size: 16px;
  line-height: 26px;
  color: var(--e-global-color-white);
}
.faq-con .accordian-inner .card-header {
  padding: 22px 22px 22px 28px;
  margin-bottom: 0;
  background: none;
  border: none;
}
.faq-con .accordian-inner .card-body {
  padding: 4px 86px 36px 28px;
}
.faq-con .accordion-card:has(.btn-link) {
  background: var(--e-global-color-accent);
  box-shadow: 10px 0 68px rgba(237, 237, 237, 60%);
}
.faq-con .accordion-card:has(.collapsed) {
  background: var(--e-global-color-white);
  box-shadow: 50px 0 100px rgb(0 0 0 / 10%);
}
.faq-con .accordion-card a.btn.btn-link:focus {
  outline: none;
  box-shadow: none;
}
.faq-con .accordian-inner .accordion-card i {
  position: absolute;
  right: 20px;
  margin: -6px 0;
}
.faq-con .accordian-inner .accordion-card .btn {
  padding: 0;
  text-decoration: none;
  text-align: left;
  display: block;
  color: var(--e-global-color-white);
}
.faq-con .accordion-card a.btn.btn-link {
  position: relative;
  padding: 0;
  border: none;
}
.faq-con .accordion-card .btn-link:before {
  content: "\f068";
  font-size: 18px;
  right: 0px;
  top: -5px;
  width: 40px;
  height: 40px;
  line-height: 42px;
  border-radius: 100px;
  color: var(--e-global-color-accent);
  background: var(--e-global-color-white);
  text-align: center;
  position: absolute;
  font-family: "FontAwesome";
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}
.faq-con .accordion-card .collapsed:before {
  content: "\2b";
  color: var(--e-global-color-white);
  background: rgb(40 128 126 / 30%);
}
