.experience-con {
  padding: 140px 0 110px;
}
.experience-con .experiencelogo-image {
  position: absolute;
  top: 45.5%;
  left: 0;
}
.experience-con .experiencelogo-image img {
  opacity: 11%;
}
.experience-con .experience_content h6 {
  padding: 5px 30px;
  margin-bottom: 22px;
  border-radius: 15px;
  display: inline-block;
  color: var(--e-global-color-accent);
  background: rgba(40, 128, 126, 10%);
}
.experience-con .experience_content {
  margin-bottom: 46px;
}
.experience-con .experience_profile {
  padding: 40px;
  margin-bottom: 138px;
  border-radius: 10px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.experience-con .profile_wrapper .profile-image img {
  border-radius: 20px;
  margin-bottom: 26px;
}
.experience-con .profile_wrapper .content {
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: inline-block;
  border-bottom: 1px solid rgba(128, 141, 158, 15%);
}
.experience-con .profile_wrapper h3 {
  font-size: 30px;
  line-height: 34px;
  text-transform: uppercase;
  color: var(--e-global-color-secondary);
}
.experience-con .profile_wrapper span {
  display: block;
  margin-bottom: 14px;
}
.experience-con .profile_wrapper .icon li {
  display: inline-block;
  margin: 0 6px;
}
.experience-con .profile_wrapper .icon li:first-child {
  margin-left: 0;
}
.experience-con .profile_wrapper .icon li:last-child {
  margin-right: 0;
}
.experience-con .profile_wrapper .icon li i {
  font-size: 18px;
  color: var(--e-global-color-text);
  transition: all 0.3s ease-in-out;
}
.experience-con .profile_wrapper .icon li:hover i {
  color: var(--e-global-color-secondary);
}
.experience-con .profile_wrapper .info li {
  margin-bottom: 6px;
}
.experience-con .profile_wrapper .info li:last-child {
  margin-bottom: 0 !important;
}
.experience-con .profile_wrapper .info li i {
  font-size: 16px;
  color: var(--e-global-color-secondary);
}
.experience-con .profile_wrapper .info li .phone {
  transform: rotate(50deg);
}
.experience-con .profile_wrapper .info li a {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  padding-left: 6px;
  color: var(--e-global-color-text);
  transition: all 0.3s ease-in-out;
}
.experience-con .profile_wrapper .info li:hover a {
  color: var(--e-global-color-secondary);
}
.experience-con .profile_content .detail {
  margin-bottom: 26px;
}
.experience-con .profile_content h3 {
  margin-bottom: 14px;
}
.experience-con .profile_content .text-size-16 {
  font-size: 16px;
  line-height: 28px;
}
.experience-con .profile_content p span {
  font-weight: 700;
  color: var(--e-global-color-secondary);
}
.experience-con .profile_content .heading {
  display: block;
}
.experience-con .profile_content .detail3 {
  margin-bottom: 36px;
}
.experience-con .profile_content .profile_button {
  font-size: 18px;
  line-height: 18px;
  padding: 21px 56px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
  transition: all 0.3s ease-in-out;
}
.experience-con .profile_content .profile_button:hover {
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
}
.experience-con .experience_wrapper h3 {
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 40px;
  display: inline-block;
}
.experience-con .experience_wrapper h3 span {
  color: var(--e-global-color-secondary);
}
.experience-con .experience-box {
  margin-bottom: 30px;
  border-radius: 10px;
  background: var(--e-global-color-white);
  box-shadow: 0 50px 100px 0 rgb(0 0 0 / 10%);
}
.experience-con .experience-box .experience-image img {
  border-radius: 10px 10px 0 0;
}
.experience-con .experience-box .content {
  padding: 30px;
}
.experience-con .experience-box ul {
  margin-bottom: 10px;
}
.experience-con .experience-box ul li {
  display: inline-block;
  margin: 0 1px;
}
.experience-con .experience-box ul li:first-child {
  margin-left: 0;
}
.experience-con .experience-box ul li:last-child {
  margin-right: 0;
}
.experience-con .experience-box ul li i {
  font-size: 16px;
  color: var(--e-global-color-vivid-orange);
}
.experience-con .experience-box h4 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 0 15px 20px 0;
  font-family: "Alegreya Sans", sans-serif;
  border-bottom: 1px solid rgba(128, 141, 158, 15%);
  transition: all 0.3s ease-in-out;
}
.experience-con .experience-box:hover a h4 {
  color: var(--e-global-color-secondary);
}
.experience-con .experience-box a:hover h4 {
  color: var(--e-global-color-accent);
}
.experience-con .experience-box .people {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience-con .experience-box .people .person {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--e-global-color-very-dark-gray);
}
.experience-con .experience-box .people .person img {
  position: relative;
  margin-right: 8px;
  top: -3px;
}
.experience-con .experience-box .people .money {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: var(--e-global-color-secondary);
}
