.event_highlight-con {
  padding: 242px 0 120px;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.event_highlight-con .eventlogo-image {
  position: absolute;
  bottom: 0;
  left: 0;
}
.event_highlight-con .eventlogo-image img {
  opacity: 11%;
}
.event_highlight-con .event_highlight_content h3 {
  margin-bottom: 12px;
  display: inline-block;
}
.event_highlight-con .event_highlight_content h3 span {
  color: var(--e-global-color-secondary);
}
.event_highlight-con .event_highlight_content p {
  font-size: 16px;
  line-height: 28px;
}
.event_highlight-con .event_highlight_content p span {
  font-weight: 700;
  color: var(--e-global-color-secondary);
}
.event_highlight-con .event_highlight_content {
  margin-bottom: 34px;
  padding: 0 50px 54px 0;
  border-bottom: 1px solid rgba(128, 141, 158, 15%);
}
.event_highlight-con .event_highlight_wrapper {
  margin-bottom: 42px;
}
.event_highlight-con .event_highlight_wrapper h3 {
  margin-bottom: 16px;
  display: inline-block;
}
.event_highlight-con .event_highlight_wrapper p {
  font-size: 16px;
  line-height: 26px;
  padding-right: 15px;
}
.event_highlight-con .event_highlight_wrapper .detail {
  margin-bottom: 32px;
}
.event_highlight-con .event_highlight_wrapper h4 {
  margin-bottom: 0;
  color: var(--e-global-color-secondary);
}
.event_highlight-con .event-box {
  padding: 22px 26px;
  border-radius: 10px;
  background: var(--e-global-color-accent);
}
.event_highlight-con .event-box h3 {
  display: inline-block;
  text-transform: uppercase;
}
.event_highlight-con .event-box .detail {
  display: inline-block;
  padding-left: 95px;
}
.event_highlight-con .event-box span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding-right: 20px;
  font-family: "Inter", sans-serif;
}
