.community-con {
  padding: 132px 0 140px;
}
.community-con .community_content {
  margin-bottom: 54px;
}
.community-con .community_content h2 {
  margin-bottom: 13px;
}
.community-con .community_content span {
  color: var(--e-global-color-secondary);
}
.community-con .community-box {
  border-radius: 10px;
  display: inline-block;
  background: var(--e-global-color-white);
  box-shadow: 0 0 100px rgb(0 0 0 / 10%);
}
.community-con .community-quoteimage {
  position: absolute;
  right: 50px;
}
.community-con .community-box .community_box_wrapper {
  position: relative;
}
.community-con .community-box .community_box_wrapper .community-image img {
  border-radius: 10px 0 0 10px;
}
.community-con .community-box .community_box_wrapper h3 {
  bottom: 30px;
  padding: 20px;
  width: 256px;
  text-align: center;
  position: absolute;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
}
.community-con .community-box .community_box_content {
  padding: 64px 42px 53px 16px;
  text-align: center;
}
.community-con .community-box .community_box_content .community-person img {
  margin-bottom: 35px;
  border-radius: 100px;
}
.community-con .community-box .community_box_content h3 {
  margin-bottom: 18px;
  color: var(--e-global-color-secondary);
}
.community-con .community-box .community_box_content p {
  margin-bottom: 16px;
  color: var(--e-global-color-text);
}
.community-con .community-box .community_box_content ul li {
  display: inline-block;
  margin: 0;
}
.community-con .community-box .community_box_content ul li:first-child {
  margin-left: 0;
}
.community-con .community-box .community_box_content ul li:last-child {
  margin-right: 0;
}
.community-con .community-box .community_box_content ul li i {
  font-size: 16px;
  color: var(--e-global-color-vivid-yellow);
}

.communitytwo-con {
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.communitytwo-con .community-box .community_box_content {
  padding: 50px 16px 38px 35px;
}
.communitytwo-con .community-box .community_box_wrapper .community-image img {
  border-radius: 0 10px 10px 0;
}
.communitytwo-con .community-box .community_box_wrapper h3 {
  right: 0;
}
.communitytwo-con .community-quoteimage {
  left: 50px;
}

/* Other Community */

.communityother-con {
  padding: 138px 0 140px;
}
