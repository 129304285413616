.expert-con {
  padding: 140px 0 136px;
  background-image: linear-gradient(
    to right,
    var(--e-global-color-light-grayish-yellow) 0%,
    var(--e-global-color-light-grayish-lime-green) 80%
  );
}
.expert-con .upper_content {
  padding-bottom: 50px;
}
.expert-con .expert-box {
  position: relative;
  text-align: center;
}
.expert-con .expert-box .expert-image img {
  border-radius: 0 127px 127px 127px;
}
.expert-con .expert-box .content {
  margin-top: -20px;
}
.expert-con .expert-box ul {
  padding: 10px 30px 6px;
  margin-bottom: 12px;
  border-radius: 10px;
  display: inline-block;
  background: var(--e-global-color-white);
  border: 1px solid var(--e-global-color-very-light-gray);
  box-shadow: 0 30px 50px 0 rgb(0 0 0 / 5%);
  transition: all 0.3s ease-in-out;
}
.expert-con .expert-box li {
  display: inline-block;
  margin: 0 6px;
}
.expert-con .expert-box li:first-child {
  margin-left: 0;
}
.expert-con .expert-box li:last-child {
  margin-right: 0;
}
.expert-con .expert-box li i {
  font-size: 20px;
  line-height: 20px;
  color: var(--e-global-color-text);
  transition: all 0.3s ease-in-out;
}
.expert-con .expert-box:hover ul {
  border: 1px solid var(--e-global-color-secondary);
  box-shadow: 0 30px 50px 0 rgb(236 89 81 / 5%);
}
.expert-con .expert-box li i:hover {
  color: var(--e-global-color-secondary);
}
.expert-con .expert_content {
  text-align: center;
}
.expert-con .expert_content h6 {
  padding: 5px 30px;
  margin-bottom: 22px;
  border-radius: 15px;
  display: inline-block;
  color: var(--e-global-color-accent);
  background: rgba(40, 128, 126, 10%);
}
.expert-con .expert_content h2 {
  margin-bottom: 18px;
}
.expert-con .expert_content p {
  margin-bottom: 34px;
}
.expert-con .expert_content .expert_button {
  font-size: 18px;
  line-height: 18px;
  padding: 21px 48px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  box-shadow: 0 20px 30px 0 rgb(236 89 81 / 20%);
  transition: all 0.3s ease-in-out;
}
.expert-con .expert_content .expert_button:hover {
  background: var(--e-global-color-accent);
  box-shadow: 0 20px 30px 0 rgb(40 128 126 / 20%);
}
