.stepProgess {
  padding-top: 20px;
}

.text-danger {
  color: #ff0000 !important;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin-top: 3px;
}

/* Extra small devices (phones, less than 576px) */

@media (max-width: 379px) {
  /* CSS rules for extra small devices */
  .stepProgess {
    padding-top: 25%;
  }
}

@media (min-width: 378.98px) and (max-width: 462.98px) {
  /* CSS rules for extra small devices */
  .stepProgess {
    padding-top: 22%;
  }
}
@media (min-width: 462px) and (max-width: 576.98px) {
  /* CSS rules for small devices */
  .stepProgess {
    padding-left: 13px;
    padding-top: 15%;
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS rules for small devices */
  .stepProgess {
    padding-top: 10%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS rules for medium devices */
  .stepProgess {
    padding-top: 10%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS rules for large devices */
  .stepProgess {
    padding-top: 8%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* CSS rules for extra large devices */
  /* .stepProgess {
    padding-top: 12%;
  } */
}

/* Extra extra large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* CSS rules for extra extra large devices */
  /* .stepProgess {
    padding-top: 10%;
  } */
}
